import React from "react";
import Toast from "../alert/alert.component";

function KanbanBoardTitle() {
  return (
    <>
      <h3 className="text-center p-2 m-2">Laboratory Timer Kanban Board</h3>
      <Toast />
      
    </>
  );
}

export default KanbanBoardTitle;
